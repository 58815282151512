<template>
  <div>
    <Card :title="'Filtros'">
      <template #card-body>
        <b-row class="mt-2">
          <b-col md="5">
            <label class="text-muted">Por Status:</label>
            <b-form-select
              v-model="unityActive"
              :options="[
                { value: '1', text: 'Empresas ativas' },
                { value: '0', text: 'Empresas inativas' },
              ]"
             
            >
              <b-form-select-option :value="''"
                >Todas as Empresas</b-form-select-option
              >
            </b-form-select>
          </b-col>
          <b-col md="6">
            <label class="text-muted">Por Nome:</label>
            <b-input v-model="unityName"></b-input>
          </b-col>
          <b-col class="d-flex align-items-end" md="1">
            <b-button class="mt-2" variant="primary-custom" @click="search"
              >Filtrar</b-button
            >
          </b-col>
        </b-row>

        <b-table class="mt-3" hover :fields="fields" :items="unities">
          <template #cell(id)="data">
            <b-dropdown
              class="p-0 m-0 d-flex"
              size="md"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <fa-icon size="1x" icon="bars" />
              </template>
              <b-dropdown-item @click="show_detail(data.item)" title="Detalhes"
                >Detalhes</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/aplicacoes_produtos/${data.item.id}`)"
                title="Detalhes"
              >
                Aplicações/Produtos</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/contas_bancarias/${data.item.id}`)"
                title="Detalhes"
              >
                Contas Bancárias</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/taxas_de_envio/${data.item.id}`)"
                title="Detalhes"
              >
                Taxas de Envio</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/taxas_de_tipo/${data.item.id}`)"
                title="Detalhes"
              >
                Taxas de Tipo</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/taxas_de_transferencia/${data.item.id}`)"
                title="Detalhes"
              >
                Taxas de Transferência</b-dropdown-item
              >
              <b-dropdown-item
                @click="$router.push(`/aplicacoes_produtos/${data.item.id}`)"
                title="Detalhes"
              >
                Config. Padrões</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </Card>
    <SweetModal
      ref="unity_detail_modal"
      overlay-theme="dark"
      hide-close-button
      width="70%"
    >
      <template #title>
        <span class="text-muted"> Detalhes </span>
      </template>
      <template>
        <b-row class="mb-2">
          <b-col md="8">
            <label>Razão Social</label>
            <b-input :value="unity_details.company_name" disabled></b-input>
          </b-col>
          <b-col md="4">
            <label>CNPJ</label>
            <b-input :value="unity_details.cnpj" disabled></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="8">
            <label> Nome Fantasia </label>
            <b-input :value="unity_details.commercial_name" disabled></b-input>
          </b-col>
          <b-col md="4">
            <label> Nome do Contato </label>
            <b-input :value="unity_details.contact_name" disabled></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6">
            <label for="">Rua</label>
            <b-input :value="unity_details.street" disabled></b-input>
          </b-col>
          <b-col md="3">
            <label>Número</label>
            <b-input :value="unity_details.number" disabled></b-input>
          </b-col>
          <b-col md="3">
            <label>Complemento</label>
            <b-input :value="unity_details.complement" disabled></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <label for="">Bairro</label>
            <b-input :value="unity_details.district" disabled></b-input>
          </b-col>
          <b-col>
            <label for="">Cidade</label>
            <b-input :value="unity_details.city" disabled></b-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label for="">Email</label>
            <b-input :value="unity_details.email" disabled></b-input>
          </b-col>
        </b-row>
      </template>
      <template #button>
        <div>
          <b-button @click="$refs.unity_detail_modal.close()">Fechar</b-button>
        </div>
      </template>
    </SweetModal>
  </div>
</template>

<script>
export default {
  props: ["unities"],
  data() {
    return {
      unityActive: "",
      unityName: "",
      unity_details: {},
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
          tdClass: "p-0 text-muted text-center pt-1",
        },
        {
          key: "commercial_name",
          label: "Nome Fantasia",
          thStyle: {
            width: "40%",
          },
          thClass: "text-muted small",
        },
        {
          key: "unity_name",
          label: "Razão Social",
          thStyle: {
            width: "40%",
          },
          thClass: "text-muted small",
        },
        {
          key: "matrix",
          label: "Matrix",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
        {
          key: "active",
          label: "Ativo",
          thStyle: {
            width: "10%",
          },
          thClass: "text-muted small",
        },
      ],
    };
  },
  methods: {
    async show_detail(data) {
      this.unity_details = {};
      const unity_details = await this.$store.dispatch(
        "unities/get_unity_details",
        data.id
      );
      if (unity_details.length > 0) {
        this.unity_details = unity_details[0];
        console.log(unity_details);
        this.$refs.unity_detail_modal.open();
      }
    },
    search() {
      
      this.$emit("search", {
        active: this.unityActive,
        company_name: this.unityName,
      });
    },
  },
};
</script>

<style>
</style>